<template>
  <div class="row mx-0 h-100 justify-content-center">
    <form @submit.prevent="forgot" class="w-100 h-100 p-0">
      <div class="col-12 px-0 bg_overlay">
        <div class="row m-0 justify-content-center">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-12 text-center p-0 mb-3 card_heading">
                Forgot Password
              </div>
              <div class="col-10 mb-3">
                <TextField type="email" placeholder="Email" color="gold" v-model="email" id="email" />
              </div>
              <div class="col-10 mb-3" v-if="hasError">
                {{hasError}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 p-0 align-self-end">
        <div class="row m-0 background-green py-3 justify-content-center">
          <div class="col-12 p-0">
            <div class="row m-0 justify-content-center">
              <div class="col-auto">
                <Button color="green_light" width="100" btnText="Submit" icon="arrow" type="submit">
                  <IconArrowForward color="white" size="size16" />
                </Button>
              </div>
              <div class="col-auto">
                <Button color="red" width="100" btnText="Login" icon="arrow" @buttonClicked="login">
                  <IconEdit color="white" size="size16" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <transition name="slideInUp">
      <Popup v-if="theStatus === 'Sent'" @close="theStatus = false">
        <div class="row mt-4 justify-content-center text-center">
          <div class="col-7 col-md-6 text-center">
            <img src="@/components/animations/tickAnimation.gif" v-if="isApp" class="img-fluid" />
            <TickAnimation v-else />
          </div>
          <div class="col-12 mb-3 text-center medium font23 green-text">
            Login Details Sent
          </div>
          <div class="col-10 mb-3 bigger_font text-center medium green-text">
            Your login details have been sent to your registered email.
          </div>
          <div class="col-10 mb-3 text-center font15">
            Note: Please check your spam/junk folder to avoid missing our response.
          </div>
        </div>
      </Popup>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue')),
    Popup: defineAsyncComponent(() => import('@/views/Popup.vue')),
    TickAnimation: defineAsyncComponent(() => import('@/components/animations/TickAnimation.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    IconEdit: defineAsyncComponent(() => import('@/components/icons/IconEdit.vue'))
  },
  name: 'Forgot',
  data () {
    return {
      email: '',
      theStatus: false,
      hasError: false
    }
  },
  computed: {
    ...mapGetters([
      'isApp'
    ])
  },
  methods: {
    ...mapActions([
      'forgotPassword'
    ]),
    login () {
      this.$emit('login')
    },
    async forgot () {
      this.theStatus = 'Sending'
      const ret = await this.forgotPassword(this.email)
      if (ret.data && ret.data.success) {
        this.theStatus = 'Sent'
      } else {
        this.hasError = ret.data.Failure
        this.theStatus = false
        setTimeout(() => {
          this.hasError = false
          this.email = ''
        }, 8000)
      }
    }
  }
}
</script>

<style scoped>
.card_heading {
  font-family: "Quicksand", Sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--green-color);
}
.background-green {
  border-bottom-left-radius: 22px;
  border-bottom-right-radius: 22px;
  color: #fff;
  font-weight: bold;
}
.cursor:hover {
  text-decoration: underline;
}
.bg_overlay {
  background-image: linear-gradient(rgba(249, 250, 251, 0.1) 0%, rgba(249, 250, 251, 0.7) 10%, rgba(249, 250, 251, 1) 100%);
}

</style>
